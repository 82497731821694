#scrollToTop {
  position: fixed;
  bottom: 70px;
  height: 40px;
  width: 40px;
  z-index: 100;

  background-color: white;
  border: 2px solid $color-red;
  border-right: 0;
  box-shadow: 0 2px 6px rgba(0,0,0,.06);
  color: $color-red;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  line-height: 40px;
  transition: opacity .3s ease-in-out, background-color .3s ease-in-out, color .3s ease-in-out;
  transform: translateX(-100%);

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &:focus,
  &:hover {
    background-color: $color-red;
    color: white;
  }
}