//.grid-2 {
//  > .ce-header {
//    margin: 0 20px;
//    @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
//      margin: 0 40px;
//    }
//    @media (min-width: $screen-lg-min) {
//      margin: 0 calc(#{percentage(1 / $grid-columns)} + 20px) 0;
//    }
//  }
//  .element-type-text,
//  .element-type-bullets,
//  .element-type-header,
//  .element-type-diaschneiderproducts_productmanagement {
//    margin: 20px;
//    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
//      margin-left: 40px;
//      margin-right: 40px;
//    }
//    @media (min-width: $screen-lg-min) {
//      margin: 0 calc(#{percentage(2 / $grid-columns)} + 20px);
//
//      &:first-child {
//        margin-top: 80px;
//      }
//      &:last-child {
//        margin-bottom: 80px;
//      }
//    }
//  }
//  .ce-default {
//    & + .ce-default {
//      margin-top: 20px;
//      @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
//        margin-top: 40px;
//      }
//      @media (min-width: $screen-lg-min) {
//        margin-top: 40px;
//      }
//    }
//  }
//
//  > .ce-header {
//    padding: 0;
//    margin-top: 20px;
//    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
//      margin-top: 40px;
//    }
//    @media (min-width: $screen-lg-min) {
//      margin-top: 80px;
//    }
//
//    & + .row {
//      .ce-default:first-child {
//        margin-top: 0;
//      }
//    }
//  }
//}

.element-type-gridelements-column {
  & + .element-type-gridelements-column {
    margin-top: 80px;
  }

  margin: 20px;
  //@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  //  margin-left: 40px;
  //  margin-right: 40px;
  //}
  @media (min-width: $screen-lg-min) {
    margin: 0 calc(#{percentage(2 / $grid-columns)} + 20px);

    &:first-child {
      margin-top: 80px;
    }
    &:last-child {
      margin-bottom: 80px;
    }
  }

  .row > .col {
    //@include margin(null $grid-gutter-width/2);
    margin-left: $grid-gutter-width/2;
    margin-right: $grid-gutter-width/2;
  }
}

.element-type-text .text-danger {
  color: #de1820;
}