.contact-flyout-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  z-index: 1100;

  .contact-flyout {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    max-width: 80%;

    transition: transform 0.15s ease-in-out;

    @media(min-width: $screen-md-min) {
      white-space: nowrap;
    }

    @media(min-height: 640px) {
      transform: translateY(-60px);
    }

    &:before {
      content: '';
      background-image: url(../Img/phone-mask.svg);
      background-size: 32px 62px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: $color-red;
      width: 51px;
      height: 120px;
      display: block;
      position: absolute;
      top: 0;
      left: -50px;
      box-shadow: 0 0 15px rgba(0,0,0,.3);

      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
    }

    .contact-flyout-inner {
      margin: 0;
      padding: 20px;
      overflow: hidden;
      color: white;
      background-color: $color-red;
      position: relative;


      strong {
        text-transform: uppercase;
        border-bottom: 2px solid white;
        display: inline-block;
        width: 100%;
      }

      p {
        margin-bottom: 20px;

        a {
          color: white;
          text-transform: none;
          font-weight: normal;

          &::before {
            display: inline;
            content: "»";
            font-weight: 400;
            padding: 0 5px 0 0;
          }

          &:hover,
          &:focus {
            color: white;
            text-decoration: none;
          }
        }
      }
      p:first-child {
        margin-bottom: 25px;
        strong {
          border-bottom: 0 none;
        }
      }
      p:last-child {
        margin-bottom: 0;
      }
    }


    &.open {
      box-shadow: 0 0 15px rgba(0,0,0,.3);
      transform: translateX(-100%) translateY(-50%);

      @media(min-height: 640px) {
        transform: translateX(-100%) translateY(-60px);
      }
    }

    .contact-link {
      margin-top: 10px;
    }
  }

  &:after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-color: #e1e1e1;
  }
}


#page {
  position: relative;
  overflow: hidden;
}