.tx-dia-softgarden {

    .name {
        color: #db0812;

        &--en {
            &:after {
                content: " | ";
                display: inline-block;
                margin-left: 4px;
                margin-right: 6px;
            }
        }

        &--de {
            position: relative;
            float: left;
            clear: both;

            @media (min-width: $screen-sm-min) {
                float: unset;
                clear: none;
            }
        }
    }
}

.tx-dia-softgarden .jobs-list__filter {

    button {
        background: white;
        width: 100%;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;

        .name,
        .icon {
            color: #db0812;
        }

        .name {
            font-weight: 500;
            max-width: 100%;
            white-space: normal;
        }
    }

    .dropdown {
        margin-bottom: 30px;
    }

    .dropdown-menu {
        font-size: 16px;
        width: 100%;

        li {
            overflow: hidden;

            & + li {
                a {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            &:last-child {
                a:after {
                    display: none;
                }
            }
        }
    }

    .name {
        float: left;
    }

    .item-count {
        margin-left: 4px;
        color: #3b3b3b !important;
    }

    a.dropdown-item {
        color: #000 !important;
        display: block;
        max-width: 100%;
        white-space: normal;
        padding-left: 34px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        font-weight: 500;

        span {
            color: #000 !important;
        }

        &:before {
            content: '>';
            float: left;
            margin-right: 4px;
            position: absolute;
            left: 20px;
        }

        &:after {
            background: #db0812;
            content: '';
            display: block;
            height: 1px;
            position: absolute;
            right: 20px;
            bottom: 0;
            left: 20px;
        }
    }

    .icon {
        margin-left: 6px;
    }

    .item-count {
        position: relative;

        &:after,
        &:before {
            content: '';
            display: inline-block;
        }

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }

    }

}

.tx-dia-softgarden .jobs-list {
    .jobs-list__header {
        .label {
            display: block;
            text-align: left;
            position: relative;
            font-size: inherit;
            text-transform: unset;
            display: block;
            border-bottom: 3px solid white;
            font-weight: 700;
            margin: 0;

            &:after {
                content: '';
                left: 0;
                bottom: -3px;
                height: 3px;
                width: 100%;
                position: absolute;
                background-color: #fff;
            }
        }

        &> .row {
            display: none;

            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }
    .job-list__item {
        a {
            color: #fff;
            display: block;
            padding: 4px 1px;
            border-bottom: 1px solid #fff;
            padding-left: 10px;
            min-height: 100%;
        }
        .row {

            &> *:first-child {
                a {
                    font-weight: bold;

                    @media (min-width: $screen-md-min) {
                        font-weight: normal;
                    }

                    &:before {
                        content: '›';
                        display: inline;
                        margin-right: 5px;
                        font-weight: 500;
                        position: absolute;
                        margin-left: -8px;
                    }
                }
            }
            &> div + div {
                a {
                    padding-left: 24px;

                    @media (min-width: $screen-md-min) {
                        padding-left: 10px;
                    }
                }
            }
            &> *:last-child {
                a {
                    @media (max-width: $screen-md-min) {
                        border-bottom: 3px solid #fff;
                    }
                }
            }
        }

        &:last-child {
            .row {
                &> *:last-child {
                    a {
                        @media (max-width: $screen-md-min) {
                            border-bottom: 1px solid #fff;
                        }
                    }
                }
            }
        }

        .row:hover {
            > div:first-child {
                a {
                    padding-left: 16px;
                    background-color: #fff;
                    color: #db0812;
                    text-decoration: none;
                }
            }
        }
    }
}

.tx-dia-softgarden .jobs-show {
    .teaser-text {
        border-top: 1px solid #444;
        border-bottom: 1px solid #444;
        color: inherit;
        margin: 0;
        position: relative !important;

        @media(min-width: $screen-lg-min) {
            position: absolute !important;
            left: 40px;
            right: 40px;
            bottom: 40px;
        }

        p {
            padding: 5px 0;
            margin: 0;
        }
    }
    .container-content {
        padding-top: 60px;
        padding-bottom: 60px;

        .content {
            padding-bottom: 30px;

            @media(min-width: $screen-md-min) {
                column-count: 2;
                column-gap: 30px;
            }
        }
    }
    .back-to-overview {
        margin-top: 30px;
    }
    .back-link {
        color: #db0812;

        &:before {
            content: "«";
            color: #db0812;
        }
    }
}

form.application {

    .powermail_fieldwrap_type_text {
        padding: 0;
        line-height: 44px;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    label {
        font-size: 16px;
    }

    .btn-default {
        color: #fff !important;
        background-color: #db0812 !important;
        border-color: #db0812 !important;

        &:hover {
            color: #db0812 !important;
            background-color: #fff !important;
            border-color: #db0812 !important;
        }
    }
}

.element-type-gridelements-container > .container > .element-type-plugin-powermail_pi1 form.application {

    @media(min-width: $screen-md-min) {
        column-count: 2;
        column-gap: 30px;

        > fieldset {
            break-inside: avoid;
        }
    }

}