//Cookie Info Head
.cookie-info {
  background: #f5f5f5;
  padding: 15px 40px;
  width: 100%;
  border: none;
  color: #4C4C4C;
  font-size: 15px;
  line-height: 1.2;
  position: relative;
  z-index: 9999999;
  overflow: hidden;
  text-align: center;
  //height: 54px;
  transition: transform 0.3s ease-out, max-height 0.3s, padding 0.3s;

  .cookie-info-content {
    position: relative;
    line-height: 24px;
  }

  &.is-closed {
    transform: translate(0, -80px);
    max-height: 0;
    padding: 0;
  }

  .cookie-info-text {
    padding-right: 40px;
  }

  a {
    color: #4C4C4C;
    font-size: 15px;
    font-weight: bold;
  }

  a.cookie-close {
    font-size: 24px;
    line-height: normal;
    font-weight: 400;
    color: #b3b3b3;
    transition: color 0.3s ease-out;
    text-align: right;
    will-change: color;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      color: #6f8f22;
      cursor: pointer;
    }

    .icon-cross2 {
      &::before {
        float: right;
      }
    }
  }
}

.d-none {
  display: none!important;
}
