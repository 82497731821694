.navbar-nav {
  > li {
    position: static;

    > .navbar-sub {
      position: absolute;
      left: 0;
      right: 0;
      display: none;
      opacity: 0;
      pointer-events: none;

      background-color: $color-gray-dark;
      padding: 90px #{calc(#{percentage((1 / $grid-columns))} + 20px)};
      //@extend .fade-in;
      transition: opacity .25s ease-in-out;

      li, a {
        color: white;
      }

      > li {
        float: left;
        margin: 0;
        width: percentage((1 / 2));

        @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
          width: percentage((1 / 4));
        }
        @media(min-width: $screen-xl-min) {
          width: percentage((1 / 5));
        }

        position: relative;
        padding: 0 20px;

        a {
          padding: 6px 0;

          &:before {
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
          }

          &:hover {
            background-color: white;
            color: $color-gray-dark;
            padding-left: 10px;
          }
        }

        &.navbar-subnav {
          padding: 0;

          > span {
            display: block;
            font-size: 35px;
            text-transform: uppercase;
            padding: 0 20px 70px;
          }

          > ul > li {
            padding: 0 20px;
            float: left;
            margin: 0;
            width: 100%;
          }

          @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
            &:first-child {
              width: percentage((1 / 2));

              > ul > li {
                width: percentage(1 / 2);
              }
            }
            &:nth-child(3) {
              width: percentage((1 / 2));
            }
          }
          @media(min-width: $screen-xl-min) {
            &:first-child {
              width: percentage((9 / 15));

              > ul > li {
                width: percentage(1 / 3);
              }
            }
            &:nth-child(3) {
              width: percentage((1 / 5));
            }
          }
        }
      }
    }

    &:hover {
      > .navbar-sub {
        display: block;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}