.element-type-timeline {
  .element-header {
    margin-top: 2rem;
  }
}

@mixin timeline-badge {
  position: absolute;
  background-color: var(--badge-color);
  border-radius: var(--badge-radius);
  bottom: calc(var(--badge-radius) * -1);
  right: calc(var(--badge-radius) * -1);
  width: calc(var(--badge-radius) * 2);
  height: calc(var(--badge-radius) * 2);
  z-index: var(--badge-z-index);
}

@mixin gradient-line {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--gradient-color1);
  background: linear-gradient(90deg, var(--gradient-color1) 0%, var(--gradient-color1) 75%, var(--gradient-color2) 100%);
}

.content-timeline {
  margin-top: 3rem;
  margin-bottom: 3rem;

  list-style: none;
  padding: 0;
  position: relative;

  --gradient-color1: #8d8d8d;
  --gradient-color2: #ffffff;
  --badge-color: #444;
  --badge-radius: 7px;
  --badge-z-index: 100;

  .timeline-milestone {
    --gradient-color1: #00a1d6;
    --badge-color: #00a1d6;
    --badge-radius: 26px;
    --badge-z-index: 200;
  }

  li.timeline-year {
    --badge-color: $primary-color;
    --badge-radius: 35px;
    --badge-z-index: 300;

    height: calc(var(--badge-radius) * 2);
    margin-bottom: 0;

    .timeline-badge {
      @include timeline-badge;

      left: calc(50% - var(--badge-radius));
      top: 0;
      bottom: unset;
      color: white;
      text-align: center;
      line-height: calc(var(--badge-radius) * 2);
      font-weight: 700;
      font-size: 1.5rem;
    }
  }

  li.timeline-year ~ li.timeline-year {
    .timeline-badge {
      border: 1px solid #000;
      color: #000;
      background-color: white;
    }
  }

  .timeline-panel {
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    figcaption {
      font-weight: 300;
      font-size: .9rem;
      margin-top: .3rem;
    }

    .timeline-roofline {
      font-weight: 300;
      font-size: 1rem;

      .timeline-roofline-text {
        text-transform: uppercase;
      }
    }

    .timeline-header {
      font-weight: 600;
      margin-top: -.1rem;
      line-height: 1.1;
      font-size: 1.6rem;

      &.timeline-header-large {
        font-size: 2.2rem;
      }
    }

    .timeline-subheader {
      margin-top: .1rem;
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.6rem;
    }

    .timeline-body {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .timeline-image,
      .timeline-body-text {
        flex-grow: 1;
        flex-basis: 0;
      }

      .timeline-image img {
        width: 100%;
        height: auto;
      }

      .timeline-body-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .timeline-teaser {
          font-weight: 300;
          font-size: 1rem;
        }

        .timeline-link {
          color: #db0812;
          text-transform: uppercase;
          margin-top: .5rem;

          &:before {
            content: ">> "
          }
        }
      }
    }
  }

  > li {
    position: relative;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-timeline li.timeline-year:before {
    @include gradient-line;
    bottom: 50%
  }
}

@media (max-width: $screen-sm-max) {
  .content-timeline .timeline-milestone--last-of-group .timeline-panel:after {
    background: var(--gradient-color1);
  }
}

@media (min-width: $screen-sm-min) {
  .content-timeline {
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #8d8d8d;
      left: 50%;
      margin-left: -1px;
    }

    > li {
      width: 50%;
      float: left;
      clear: left;

      &.timeline-year {
        margin-bottom: 2rem;

        .timeline-badge {
          left: calc(100% - var(--badge-radius));
        }
      }

      &.timeline-year-inverted {
        .timeline-badge {
          left: calc(-1 * var(--badge-radius));
        }
      }

      &.timeline,
      &.timeline-inverted {
        margin-bottom: 8rem;
      }

      .timeline-panel {
        width: calc(100% - 36px);
        padding-right: 24px;

        .timeline-body {
          flex-direction: row;
        }

        &:after {
          @include gradient-line;
        }
      }

      .timeline-badge {
        @include timeline-badge;
      }

      &.timeline-inverted {
        .timeline-panel {
          float: right;
          padding-left: 24px;
          padding-right: unset;

          .timeline-body {
            flex-direction: row-reverse;
          }
        }

        .timeline-badge {
          right: unset;
          left: calc(var(--badge-radius) * -1);
        }
      }
    }

    > li.timeline-inverted,
    > li.timeline-year-inverted {
      float: right;
      clear: right;
    }

    > li.timeline-milestone {
      &:before {
        content: unset;
      }

      .timeline-badge {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: unset;
        background-image: url(/typo3conf/ext/dia_common/Resources/Public/Img/Icon-Milestone.svg);
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .content-timeline {
    > li {
      .timeline-panel {
        padding-left: 72px;

        &:after {
          background-image: linear-gradient(270deg, var(--gradient-color1) 0%, var(--gradient-color1) 75%, var(--gradient-color2) 100%);
        }
      }

      &.timeline-inverted {
        .timeline-panel {
          padding-right: 72px;

          &:after {
            background-image: linear-gradient(90deg, var(--gradient-color1) 0%, var(--gradient-color1) 75%, var(--gradient-color2) 100%);
          }
        }
      }
    }
  }
}


.timeline-categories {
  margin: 30px 0 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {

      > a {
        text-transform: uppercase;
        background-color: $color-gray-dark;
        display: block;
        color: white;
        padding: 5px;

        &:hover {
          text-decoration: none;
          background-color: $color-red;
        }
      }

      &.active {
        a {
          text-decoration: none;
          background-color: $color-red;
        }
      }
    }
  }

  @media(min-width: $screen-md-min) {
    ul {
      display: flex;
      flex-wrap: wrap;

      > li {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        & + li {
          padding-left: 1px;
        }

        a {
          height: 100%;
        }
      }
    }
  }
}