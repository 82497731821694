.element-type-uploads {
  ul {
    font-size: 16px;
    overflow: hidden;
    margin: 0;

    li {
      margin: 0 0 30px 0;
      float: left;
      width: 33.3333%;

      span {
        &.icon {
          color: #fff;
          font-size: 48px;
          display: block;
          width: 60px;
          float: left;
        }
      }

      span {
        margin: 0;
        float: left;
        width: 70%;

        a {
          color: #317184 !important;
        }
      }

      a {
        font-weight: 500;
      }
    }
  }
}

.opthalmics.product-schneider-om,
.opthalmics.lines-schneider-om,
.precision.product-schneider-om,
.precision.lines-schneider-om {
  .element-type-uploads {
    width: 100%;
    padding: 100px 10%;
    margin-right: auto;
    margin-left: auto;
    background-color: #e6e6e6;

    .ce-header {
      h3 {
        font-size: 35px;
        color: #444;
      }
    }
    .ce-uploads {
      li {
        width: 25%;

        @media (max-width: 560px) {
          width: 33.33%;
        }

        @media (max-width: 480px) {
          width: 50%;
        }

        &:hover {
          color: #317184;
          background-color: #e6e6e6;
        }

        span {

          &.icon {
            color: white;
          }
          &:hover {
            color: #317184;
            background-color: #e6e6e6;
          }
        }

        a {
          &:hover {
            color: #317184;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
}

