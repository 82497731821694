#thumbnails-carousel {
  .carousel-inner {
    width: 66.66666667%;
  }

  .carousel-control-wrapper {
    position: relative;
    a {
      background-color: $color-red;
      border: 1px solid #fff;
      opacity: 1;
      left: 0;
      right: 0;
      width: 100%;
      &:hover {
        opacity: .95;
      }
      &:after {
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: 0;
        width: 100%;
        font-size: 70px;
        line-height: 70px;
        -webkit-transform:  translateY(-50%);
        -ms-transform:      translateY(-50%);
        transform:          translateY(-50%);
      }
      &.left:after {
        color: #fff;
        content: "‹";
      }
      &.right:after {
        color: #fff;
        content: "›";
      }
    }
  }
}
@media (max-width: 1199px) {
	#page-1 .carousel .item:first-child {
		height: auto !important;
	}
}

@media (max-width: 767px) {
  #page-1 .carousel .item {
    height: auto !important;
  }
}