h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: 300;
  color: #444;
  margin: 0 0 30px 0;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 25px;
}



@media (max-width:1024px) {
  h1, h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
  }
}
