.ac_results {
  background-color: #fff;

  ul {
    padding: 0;
    margin: 0 auto;

    li {
      padding: 0.5em 1em;
      border-bottom: 1px solid #f7f7f7;
      transition: color 0.2s ease-out;

      color: #444;

      &:last-child {
        border-bottom: 0;
      }

      &:hover,
      &:hover .text-muted {
        color: $color-red;
      }
    }

    .result {
      display: block;
      max-width: 1400px;
      margin: 0 auto;
    }

    .text-muted {
      padding-left: 1em;
    }
  }
}


/** Styling of indexed search autocomplete results */
/* Search Form in Header area */
.ac_results {
  width: 100% !important;
  background: none !important;
  z-index: 1000 !important;
  top: 122px !important;

  .container-fluid {
    padding: 0 !important;
  }

  iframe {
    display: none;
  }

  ul {
    width: 100% !important;
    display: block;

    li {
      width: 100%;
      display: block;
      font-size: 22px;
      color: #fff;

      &.even, &.odd {
        background: #808080 !important;
      }

      &:hover {
        cursor: pointer;
      }

      &.over {
        background: #e5e5e5 !important;
      }

      .text-muted {
        color: #fff;
      }

      .occurency {
        font-weight: 400;
      }
    }
  }
}