#body.news_events {

  #main>.ce-default.grid-2 {
    border: 0 none;
  }

  background-color: $color-red;
  padding-top: 80px;
  padding-bottom: 40px;

  .row {
    margin-right: -20px;
    margin-left: -20px;
  }

  [class*=col-] {
    padding-left: 20px;
    padding-right: 20px;
  }

  * {
    color: white;
  }

  h1, .h1 {
    margin-bottom: 50px;
  }

  h2, .h2 {
    font-size: inherit;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 3px solid white;
  }

  a {
    display: block;

    transition: padding 0.2s ease-out;
    -moz-transition: padding 0.2s ease-out;
    -webkit-transition: padding 0.2s ease-out;
    -o-transition: padding 0.2s ease-out;

    &:hover {
      text-decoration: none;
      background-color: white;
      color: $color-red;
      padding-left: 5px;
    }
  }

  .eventList {
    @include make-row;
    @include row-eq-height;

    .article {
      margin-bottom: 40px;

      @include make-xs-column(12);
      @include make-md-column(6);

      .header {
        a {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .teaser-text {
        margin: 0;
        border-top: 3px solid white;
        border-bottom: 3px solid white;

        p {
          line-height: 28px;
          margin: 0;
          border-bottom: 1px solid white;

          &:last-child {
            border-bottom: 0 none;
          }
        }
        .more {
          line-height: 28px;

          &:before {
            content: '››';
            display: inline;
            padding-left: 1px;
            margin-right: 5px;
            font-weight: 500;
          }
        }
      }
    }
  }
}