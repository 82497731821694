@mixin button-variant-custom($color, $background, $border) {
  color: $color!important;
  background-color: $background!important;
  border-color: $border!important;

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background!important;
    background-color: $color!important;
    border-color: $background!important;
  }
}


.btn {
  border-width: 2px;
  font-size: 16px;
  padding: 8px 40px;
  transition: all .2s ease-out;
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant-custom($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
  @include button-variant-custom($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
// Success appears as green
.btn-success {
  @include button-variant-custom($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
  @include button-variant-custom($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant-custom($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  @include button-variant-custom($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Custom buttons
// --------------------------------------------------
.btn-red {
  @include button-variant-custom(#fff, #db0812, #db0812);
}
.btn-apply-now {
  @include button-variant-custom(#fff, #db0812, #db0812);

  width: 100%;

  @media(min-width: 270px) {
    width: auto;
    min-width: 240px;
  }
}

