.element-type-diaschneiderproducts_productmanagement {
  .product-show {
    .header-image {
      position: relative;

      @media(min-width: $screen-sm-min) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .product-header-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;

        @media(max-width: $screen-xs-max) {
          position: relative;
          padding-bottom: percentage(600 / 1680);
        }
      }

      .product-header-text {

        @media(max-width: $screen-xs-max) {
          .c1x1 {
            padding-top: 0;
            >div {
              position: relative;
            }
          }
          .slider-item-header {
            font-size: 25px;
          }
        }
        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          margin-left: percentage((1 / $grid-columns));
          width: percentage((8 / $grid-columns));
        }
        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
          margin-left: percentage((1 / $grid-columns));
          width: percentage((6 / $grid-columns));
        }
        @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
          margin-left: percentage((1 / $grid-columns));
          width: percentage((5 / $grid-columns));
        }
        @media(min-width: $screen-xl-min) {
          margin-left: percentage((1 / $grid-columns));
          width: percentage((4 / $grid-columns));
        }
      }
    }
  }
}


.precision.product-schneider-om,
.opthalmics.product-schneider-om {

  ul.product-list {
    list-style: none;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;

    @include make-row;

    li {
      margin: 0;
      @include make-xs-column(12);
      @include make-md-column(6);
      border: 0 none;

      &:last-child {
        margin-right: 0;
      }

      span {
        transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
      }

      span, a {
        display: block;
        font-weight: 500;

        &:hover, span:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      a {
        border-bottom: 2px solid #ececec;
      }
    }
  }

  .header-image {
    .element-type-text {
      padding: 20px;

      ul {
        -moz-column-count: auto;
        -webkit-column-count: auto;
        -ms-column-count: auto;
        -o-column-count: auto;
        column-count: auto;

        li {
          width: 100%;
          margin: 0;
          padding: 0;
          border: none;

          a {
            border: none;
            font-size: 24px;
            font-weight: 400;
            padding: 0;

            &:hover {
              background: none;
            }
          }

          &:hover {
            background: none;
            padding: 0;
          }
        }
      }
    }
  }

  .element-type-text,
  .element-type-bullets {

    p {
      margin: 0 0 12px 0;
      line-height: 1.2;
    }
  }


  .product-list-filtered {
    padding: 0;
  }

  .element-type-image {
    .ce-textpic-imagewrap {
      margin: 0;
    }
  }
}

#filter-disabled {
  background: #f7f7f7;
  p {
    margin: 0 0 12px 0;
    line-height: 1.2;
  }

  ul.product-list {
    li {
      float: left;
      cursor: pointer;

      span, a {
        padding: 4px 15px;
        border-bottom: 2px solid #ececec;
      }
    }
  }
}

.products-text-static {
  padding: 80px 20%;
  height: 100%;
}

.products-gallery-static {

  &.carousel {
    margin: 0;
  }

  .item {
    /*float: left;*/
    /*background: $color-red;*/
  }

  .carousel-control-right {
    float: right;
    width: 100%;
    height: 100%;
    background: $color-red;
  }

  .right.carousel-control {
    background: $color-red;
    left: inherit;
    top: inherit;
    right: 0;
    bottom: 0;
    width: 100%;
    text-shadow: 0;
    position: static;
    opacity: 1.0;
    height: 100%;
    min-height: inherit;
    display: block;

    span.icon {
      position: relative;
      color: white;
      font-size: 40px;
      vertical-align: middle;
      width: 100%;
      display: block;
      transform: translateY(-50%);
      top: 50%;

      &::before {
        display: inline-block;
        width: 22px;
      }
    }
  }

  .item-inner {
    position: relative;
    overflow: hidden;

    img {
      transition: transform 0.6s ease-out 0s;
      transform: scale(1.02);
    }

    a {
      display: block;
    }

    &:hover {
      img {
        transform: scale(1.2);
        z-index: 2;
      }

      div.title {
        display: block;
        z-index: 3;

        span {
          color: #fff;
        }
      }

      a:hover {
        text-decoration: none;

        span.title {
          color: #fff;
        }
      }
    }

    div.title {
      display: none;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      transition: transform 0.6s ease-out 0s;

      .title-inner {
        height: 100%;
        width: 100%;
        display: table;
      }

      span {
        font-weight: 500;
        color: #fff;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

#filter-active {
  #products-gallery {
    .item-inner {
      position: relative;
      overflow: hidden;

      img {
        transition: transform 0.6s ease-out 0s;
        transform: scale(1.0);
      }

      a {
        display: block;
      }

      &:hover {
        img {
          transform: scale(1.2);
          z-index: 2;
        }

        div.title {
          display: block;
          z-index: 3;

          span {
            color: #fff;
          }
        }

        a:hover {
          text-decoration: none;

          span.title {
            color: #fff;
          }
        }
      }

      div.title {
        display: none;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        transition: transform 0.6s ease-out 0s;

        .title-inner {
          height: 100%;
          width: 100%;
          display: table;
        }

        span {
          font-weight: 500;
          color: #fff;
          height: 100%;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}

#body .ce-default.element-type-diaschneiderproducts_productmanagement {
  overflow: inherit;
}

#filter-active {
  /*height: 720px;*/

  [class*="col"] {
    height: 100%;
  }

  h2 {
    color: #fff;
  }

  #product-filter {
    height: 100%;
    background: $color-red;
    color: #fff;
    position: relative;

    padding: 20px;
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      padding: 40px;
    }
    @media (min-width: $screen-lg-min) {
      padding: 80px calc(#{percentage(2 / $grid-columns)} + 20px);
    }

    span.close {
      position: absolute;
      right: 40px;
      top: 40px;
      font-size: 32px;
      display: block;
      opacity: 1;
      font-weight: 400;
      text-shadow: inherit;

      span.icon {
        display: block;
        width: 28px;
        color: #fff;
      }
    }

    ul.product-list {
      li {
        padding: 4px 0;
        border-bottom: 2px solid #fff;

        transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;

        &:hover {
          background: #fff;
          a {
            background: none;
            color: $color-red;
          }
        }

        span, a {
          color: #fff;
          transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          -webkit-transition: all 0.2s ease-out;
          -o-transition: all 0.2s ease-out;
        }
      }
    }
  }

  #products-gallery {
    a {
      display: block;
      float: left;
    }
  }
}

/** Detailseite **/
.precision.product-schneider-om,
.opthalmics.product-schneider-om {
  .product-show {
    video {
      width: 100%;
    }

    .content {
      padding: 100px 0 0;

      .text-container {
        border-bottom: 1px solid $color-gray-light;
      }

      #description {
        margin: 0 0 100px 0;

        @media(min-width: $screen-md-min) {
          -webkit-column-count: 2;
          -ms-column-count: 2;
          -o-column-count: 2;
          -moz-column-count: 2;
          column-count: 2;

          -webkit-column-gap: 40px;
          -moz-column-gap: 40px;
          column-gap: 40px;
        }
      }

      .image-gallery-text {
        .image-gallery {
          height: 100%;
          background: $color-gray-light;

          img {
            margin: 0 2px 0 0;
            display: block;
            float: left;
          }
        }

        .element-type-text {
          ul {
            -moz-column-count: auto;
            -webkit-column-count: auto;
            -ms-column-count: auto;
            -o-column-count: auto;
            column-count: auto;

            li {
              padding-top: 4px;
              padding-bottom: 4px;
              border-bottom: 2px solid #ececec;
              font-weight: 500;
              cursor: inherit;
              width: 100%;
            }
          }
        }
      }

      #data,
      #downloads {
        background: #f1f1f1;
        padding: 100px 0;

        h2 {
          color: #444;
        }

        p {
          margin: 0;
          padding: 0;

          &.medium-bold {
            font-weight: 500;
          }
        }
      }

      #data .h-equal,
      #data .equalheight {
        [class*="col"] {
          background: #fafafa;
          padding: 12px 40px;
          border-bottom: 2px solid #f1f1f1;
        }
      }

      #downloads {
        background: #e6e6e6;

        .item {
          margin: 0 0 30px 0;
        }

        span {
          &.icon {
            color: #fff;
            font-size: 48px;
            display: block;
            width: 60px;
            float: left;
          }
        }

        a {
          font-weight: 500;
        }
      }
    }
  }
}

/** Listenseite gefiltert **/
.product-list-filtered {
  padding: 0 180px;
}

.lines-schneider-om {
  .product-list-filtered {
    padding: 0;
  }
}

@media(min-width: $screen-xs-min) and (max-width: $screen-xs-max) {

}
@media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

}
@media(min-width: $screen-md-min) and (max-width: $screen-md-max) {

}
@media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

}
@media(min-width: $screen-xl-min) {

}

.opthalmics,
.opthalmics {

  .product-schneider-om {

  }
}


///** Mobile **/
//@media (max-width: 1199px) {
//  .precision.product-schneider-om .element-type-text,
//  .opthalmics.product-schneider-om .element-type-text,
//  .products-text-static,
//  .precision.product-schneider-om .element-type-gridelements-column,
//  .opthalmics.product-schneider-om .element-type-gridelements-column,
//  #filter-active #product-filter {
//    padding: 10%;
//  }
//
//  .precision.product-schneider-om .product-show .content,
//  .opthalmics.product-schneider-om .product-show .content {
//    padding: 0;
//  }
//
//}
//
//@media (max-width: $screen-xs-max) {
//  .precision.product-schneider-om .element-type-text,
//  .opthalmics.product-schneider-om .element-type-text {
//    padding: 20px;
//  }
//
//  .precision.product-schneider-om .product-show .row,
//  .opthalmics.product-schneider-om .product-show .row {
//    margin: 0;
//  }
//
//  .precision.product-schneider-om ul.product-list li,
//  .opthalmics.product-schneider-om ul.product-list li {
//    width: 100%;
//    margin: 0;
//  }
//}
//
//@media (max-width: 768px) {
//  .precision.product-schneider-om .element-type-gridelements-column .element-type-text,
//  .opthalmics.product-schneider-om .element-type-gridelements-column .element-type-text {
//    padding: 0 !important;
//  }
//
//  .precision.product-schneider-om .product-show .content,
//  .opthalmics.product-schneider-om .product-show .row .content {
//    padding: 10px 0 0;
//  }
//
//  .precision.product-schneider-om .product-show .content #data,
//  .precision.product-schneider-om .product-show .content #downloads,
//  .opthalmics.product-schneider-om .product-show .content #data,
//  .opthalmics.product-schneider-om .product-show .content #downloads {
//    padding: 5% 0;
//  }
//}
//
//@media (max-width: 560px) {
//  .precision.product-schneider-om .product-show .content #description,
//  .opthalmics.product-schneider-om .product-show .content #description {
//    -webkit-column-count: inherit;
//    -moz-column-count: inherit;
//    column-count: inherit;
//  }
//}