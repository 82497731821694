#header {
  background: #fff;
  //overflow: hidden;
  /*height: 146px;*/

  &.navbar {
    margin: 0;
    border: none;
  }

  .navbar-top {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    float: left;

    .navbar-mobil-switch {
      float: left;
      margin: 1px 0 0 0;

      .li-mmenu {
        font-size: 22px;
        line-height: 44px;
        display: block;
        width: 44px;
        height: 44px;
        margin: 0 4px 0 0;
        padding: 8px;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        text-align: center;
        color: #FFF;
        border-radius: 0 0 6px 6px;
        background: #e1e1e1;

        &:hover {
          text-decoration: none;
          background: #808080;
        }

        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .container-header {
    padding: 0 40px;
    @include clearfix;

    .navbar-collapse {
      padding: 0;

      &.header-navbar {
        overflow-x: visible;
        height: 44px;
        margin: 0 0 50px 0;

        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }

      > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          float: left;

          &#search-open,
          &#language-open {
            font-size: 22px;
            line-height: 44px;
            position: relative;
            width: 44px;
            height: 44px;
            margin: 0 4px 0 0;
            cursor: pointer;
            -moz-transition: background 0.2s ease-out;
            -o-transition: background 0.2s ease-out;
            -webkit-transition: background 0.2s ease-out;
            transition: background 0.2s ease-out;
            text-align: center;
            color: #FFF;
            border-radius: 0 0 6px 6px;
            background: $color-gray-dark;

            .icon-search3,
            .icon-sphere {
              transition: opacity .3s ease-in-out;
              opacity: 1;
            }

            .icon-cross2 {
              position: absolute;
              top: 50%;
              left: 50%;
              transition: opacity .3s ease-in-out;
              transform: translate(-50%, -50%);
              opacity: 0;
            }

            &.active {
              .icon-search3,
              .icon-sphere {
                opacity: 0;
              }

              .icon-cross2 {
                opacity: 1;
              }
            }

            &:hover, &.active {
              background: #808080;
            }
          }

          &#language-open {
            margin-left: 10px;
          }
        }

        .current-lang {
          line-height: 44px;
          padding-left: 5px;
        }
      }
    }
  }

  .navbar-header {
    float: right;

    .logo {
      position: relative;
      top: 20px;
      right: 0;

      img {
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
      }
    }
  }
}

/** Mobile **/
@media (max-width: 1250px) {
  #header-sticky-wrapper {
    height: auto !important;
  }
}

.mm-opening #header .navbar-top .navbar-mobil-switch .li-mmenu {
  text-decoration: none;
  background: #808080;
}

#header-sticky-wrapper.is-sticky {
  #header {
    width: 100%;
    max-width: 1680px;
    /*height: 106px;*/
  }

  .logo img {
    width: 0;
    height: 0;
  }

  .container-header .navbar-collapse.header-navbar {
    overflow: hidden;
    height: 0%;
    margin: 0;
  }
}

body.fe-id-pagets__HomeSchneider {
  #header-sticky-wrapper.is-sticky {
    #header {
      -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
      -ms-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
      -o-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
      -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
    }
  }
}


/** Mobile **/
@media (max-width: $screen-sm-max) {
  #header .container-header .navbar-collapse.header-navbar {
    margin: 0 0 50px 8px;
  }

  #header .container-header .navbar-collapse ul li#search-open {
    margin: 0 0 0 40px;
  }

  #lang {
    margin: 0 0 0 20px;
  }

  #header .navbar-header .logo {
    margin: 0 0 50px 0;
  }

  #header .navbar-top {
    top: 15px;
    left: 25px;

    .navbar-mobil-switch {
      .li-mmenu {
        padding: 0;
        color: $color-red;
        background: none;

        #nav-icon3 {
          width: 30px;

          span {
            height: 3px;
            color: $color-red;
            border-radius: 0;
            background: $color-red;
          }
        }

        &:hover {
          background: none;
        }
      }
    }
  }

  .mm-opening #header .navbar-top .navbar-mobil-switch .li-mmenu {
    background: none;
  }
}


@media (max-width: 768px) {
  #header .navbar-top {
    left: 15px;
  }

  #header .container-header .navbar-collapse ul li#search-open {
    margin: 0 0 0 25px;
  }

  #lang {
    margin: 0 0 0 15px;
  }

  #header .navbar-header .logo {
    right: -15px;
  }
}


@media (max-width: 460px) {
  #header {
    [class*="col-"] {
      padding: 0;
    }

    .row {
      margin: 0;
    }
  }
}