.header-lined {
  .element-header {
    border-top: 2px solid #444444;
    border-bottom: 2px solid #444444;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.container-red,
.container-gray {
  background-color: $color-red;
  color: white;
  padding-top: 60px;
  padding-bottom: 30px;

  *, a, p a {
    color: white;
  }

  .element-type-plugin-news_pi1 {
    .element-header {
      font-size: inherit;
      text-transform: unset;
      display: block;
      border-bottom: 3px solid white;
      font-weight: 700;
      padding: 4px 1px;
      margin: 0;
    }
    .list-lined {
      @include listLined(#ffffff, $color-red);
      @include columns(1, 1, 2, 2, 2);

      > li {
        margin: 0;
        padding: 0;
        border: 0 none;
        @media screen and (min-width:0\0), only screen and (device-width: 768px), only screen and (device-width: 1024px) {
          display: inline-block;
          width: 100%;
        }

        -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid; /* IE 10+ */
      }
    }
  }
}
.container-gray {
  background-color: $color-gray;
  .element-type-plugin-news_pi1 {
    .list-lined {
      @include listLined(#ffffff, $color-gray);
    }
  }
}