.language-menu {
  position: relative;
  z-index: 1001;
  display: none;
  width: 100%;
  animation-duration: 1s;
  background: #808080;
}

.nav-lang {
  display: flex;
  align-items: center;
  height: 74px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  > li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: #fff;

    &:focus,
    &:hover {
      text-decoration: none;
      color: #444;
    }
  }

  &--mobile {
    display: none;

    @media (max-width: 767px) {
      display: flex;

      li {
        margin: 0;
        padding: 0 10px;
        border-right: 1px solid #fff;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
}