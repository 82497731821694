
@mixin form-parsley-state($state, $color) {
  .form-control,
  .custom-select {
    &.parsley-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
      }
    }
  }
  .radio.parsley-#{$state},
  .checkbox.parsley-#{$state} {
    color: $color;
  }
}

@include form-parsley-state("success", $state-success-text);
@include form-parsley-state("error", $state-danger-text);

.parsley-errors-list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 5px;

  &.filled {
    display: block;
  }
}