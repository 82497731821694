.diaschneiderproducts-list {
  background-color: $color-red;
  color: white;

  padding: 20px;
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    padding: 40px 40px 20px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 80px 10% 40px;
    padding-left: calc(#{percentage(1 / $grid-columns)} + 20px);
    padding-right: calc(#{percentage(1 / $grid-columns)} + 20px);
  }

  h2.title {
    color: white;
  }

  .description {
    margin-bottom: 40px;
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-flow: row wrap;

    &:after {
      content: "";
      flex: auto;
    }

    //@include columns(1, 2, 2, 3, 5, 40px);

    > li {
      flex: none;
      padding: 0 20px;
      @media(max-width: $screen-xs-max) {
        width: 100%;
      }
      @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        width: 50%
      }
      @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        width: calc(100% / 3);
      }
      @media(min-width: $screen-xl-min) {
        width: 20%;
      }
      margin-bottom: 40px;

      //@media screen and (min-width:0\0), only screen and (device-width: 768px), only screen and (device-width: 1024px)  {
      //  display: inline-block;
      //  width: 100%;
      //}

      -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
      break-inside: avoid; /* IE 10+ */
      page-break-inside: avoid; /* Firefox */

      .filterCategory {
        color: white !important;
        display: block;
        border-bottom: 3px solid white;
        font-weight: bold;
        padding: 4px 1px;
      }

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        display: inline-block;
        width: 100%;

        > li {
          a {
            color: white !important;
            display: block;
            padding: 4px 1px;
            border-bottom: 1px solid white;

            &:before {
              content: '›';
              display: inline;
              margin-right: 5px;
              font-weight: 500;
            }

            &:hover {
              padding-left: 6px;
              background-color: white !important;
              color: $color-red !important;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}