.element-type-gridelements-slider {
  &.h-equal {
    .carousel-inner {
      > .item {
        > .element {
          height: 100%;
        }
      }
    }
  }
}