@mixin columns($count-xs: 1, $count-sm: 2, $count-md: 2, $count-lg: 4, $count-xl: 4, $gap: 40px) {
  @include content-columns($count-xs, $gap);

  @media(min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    @include content-columns($count-xs, $gap);
  }
  @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include content-columns($count-sm, $gap);
  }
  @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include content-columns($count-md, $gap);
  }
  @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @include content-columns($count-lg, $gap);
  }
  @media(min-width: $screen-xl-min) {
    @include content-columns($count-xl, $gap);
  }
}