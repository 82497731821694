/**
 * CUSTOM SCSS file
 */

html, body {
  font-size: 1em;
  color: #444;
  background: #e1e1e1;
  font-family: 'Ubuntu', sans-serif;
  height: 100%;

  #page {
	max-width: 1680px;
	margin: 0 auto;
  }

  [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

body.fe-id-pagets__HomeSchneider,
body.fe-id-pagets__MainSchneider,
body.fe-id-pagets__OverviewSchneider,
.precision.product-schneider-om,
.opthalmics.product-schneider-om {
  [class*="col-"] {
    padding: 0;
  }
  .row {
    margin: 0;
  }
}

.container,
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;

  @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
    padding-left: 40px;
    padding-right: 40px;
  }
  .row {
    margin-right: -20px!important;
    margin-left: -20px!important;
  }
  [class*="col-"] {
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
}

.ce-default {
  padding: 0;
  position: relative;

  a.anchor {
    position: absolute;
    top: -116px;
    left: 0;
    font-size: 0;
    line-height: 0;
  }
}

p, span {
  font-size: inherit;
  color: inherit;
}

a {
  color: #444;
  outline: none;

  //display: inline-block;
  //overflow: hidden;
  //max-width: 100%;
  //text-overflow: ellipsis;
  //white-space: nowrap;

  transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;

  &:hover, &:focus {
    background: none;
    color: $color-red;
    outline: none;
    text-decoration: underline;
  }
}

.element-type-text p a {
  color: $color-red;
  text-transform: uppercase;
  font-weight: 500;

  &::before {
    content: "»";
    padding: 0 4px 0 0;
    display: inline-block;
  }
}

input {
  outline: none;
}


/* no padding in element */
.padding-above-none {
  padding-top: 0 !important;
}

.padding-below-none {
  padding-bottom: 0 !important;
}

/* small padding in element */
.padding-above-small {
  padding-top: 40px !important;
}

.padding-below-small {
  padding-bottom: 40px !important;
}

/* middle padding in element */
.padding-above-medium {
  padding-top: 60px !important;
}

.padding-below-medium {
  padding-bottom: 60px !important;
}

/* big padding in element */
.padding-above-large {
  padding-top: 100px !important;
}

.padding-below-large {
  padding-bottom: 100px !important;
}

.element-type-text,
.product-list-filtered {
  ul, ol {
    list-style: none;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;

    li {
      border-bottom: 2px solid #ECECEC;
      cursor: inherit;
      padding: 0;
      margin: 0;
      float: left;
      width: 100%;

      a,
      span {
        padding: 4px 15px;
        display: block;
        font-weight: 500;
      }
      a {
        transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;

        &:before {
          content: '›';
          display: inline;
          margin-right: 5px;
          font-weight: 500;
        }

        &:hover {
          padding-left: 20px;
          text-decoration: none;
        }
      }
    }
  }


  ul.product-list {
    li {
      width: 45%;
      margin: 0 5% 0 0;
    }
  }
}

.element-type-bullets-default,
.element-type-bullets {
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 4px 0;
      border-bottom: 2px solid #ECECEC;
      margin: 0;
      float: left;
      font-weight: normal !important;
      width: 100%;

      a {
        transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding-left: 10px;

        &:hover {
          margin-left: -5px;
        }
      }
      &:hover {
        color: #fff;
        background: #444;
      }

      &:hover {
        background: none !important;
        color: #444 !important;
        &::before {
          color: #317184 !important;
        }
      }
    }
  }
}



/** Mobile **/
@media (max-width:768px) {
  .no-padding-above,
  .no-padding-below {
    padding: 40px !important;
  }
}

@media (max-width:480px) {
  .element-type-text ul.product-list li {
    width: 100%;
    margin: 0;
  }

  .element-type-uploads ul li {
    width: 100%;
  }
}