.carousel {
  /*margin: 0 0 30px 0;*/

  .ce-textpic-imagewrap {
    margin: 0;
  }

  .item {
    img {
      //float: right;
      width: 100%;
      height: auto;
    }
  }

  .carousel-control {
    background: none;
    display: block;
    left: 0;
    top: 50%;
    bottom: auto;
    width: 90px;
    transform: translateY(-50%);

    .arrow {
      font-size: 90px;
      color: white;
    }

    &:hover, &:focus {
      background-color: transparent;
    }
  }

  .right.carousel-control {
    right: 0;
    left: inherit;
    height: auto;
  }

  .element-type-image {
    .image-wrap {
      @include clearfix;
      position: relative;

      .caption {
        &:before {
          content: 'i';
          font-size: 30px;
          line-height: 70px;
          width: 70px;
          height: 70px;
          position: absolute;
          left: 0;
          top: -70px;
          text-align: center;
          background-color: white;
          cursor: pointer;
        }

        @include translate3d(0, 100%, 0);
        @include transition-transform(0.3s ease-in-out);

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 70px;
        height: auto;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 15px 30px;

        &.open {
          @include translate3d(0, 0, 0);
        }
      }
    }
  }
}

//.thumbnails-carousel {
//  list-style: none;
//  float: left;
//  padding: 0;
//  margin: 0;
//  width: 100%;
//
//  .carousel-inner {
//    background: $color-gray-light;
//  }
//
//  .item {
//
//    img {
//      cursor: pointer;
//      border: 1px solid #fff;
//      width: 100%;
//      height: auto;
//    }
//  }
//
//  [class*="col-"] { padding: 0; }
//}
.thumbnails-carousel {
  background-color: $color-gray-dark;
  overflow: hidden;

  .thumbnails-carousel-inner {
    position: relative;
    overflow: hidden;
    margin-bottom: -1px; // Firefox fix
    width: 100%;

    @include translate3d(0, 0, 0);
    @include transition-transform(0.6s ease-in-out);

    > .item {
      @include clearfix;
      float: left;
      display: block;
      left: 0;
      @include transition(.6s ease-in-out left);
      height: auto !important;
      width: 20%;
      border: 1px solid white;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      box-sizing: border-box;
      cursor: pointer;

      > a {
        display: block;
      }

      > img,
      > a > img {
        @include img-responsive;
        float: none;
      }
    }
  }
}

.fe-id-pagets__HomeSchneider,
.fe-id-pagets__LinesSchneider,
.fe-id-pagets__ProductSchneider {
  .carousel {
    margin: 0;
  }
}


// Main Slider
.element-layout-teaser-slider {

  .carousel-indicators,
  .teaser-item-content {
    @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      margin-left: percentage((1 / $grid-columns));
      width: percentage((5 / $grid-columns));
    }
    @media(min-width: $screen-xl-min) {
      margin-left: percentage((1 / $grid-columns));
      width: percentage((4 / $grid-columns));
    }
  }

  .carousel-indicators {
    @media(min-width: $screen-sm-min) {
      left: 0;
    }

    li {
      width: 12px;
      height: 12px;
      border: 0 none;
      background-color: white;
      opacity: 0.5;
      margin: 0;

      &.active {
        opacity: 1;
      }

      & + li {
        margin-left: 5px;
      }
    }
  }
}

.element-layout-teaser-slider {
  @media(max-width: $screen-md-max) {
    .fade-in {
      animation: none;
      opacity: 1;
    }
    .carousel-indicators {
      margin: calc(#{percentage(600 / 1680)} - 30px) 0 0;
      padding: 0;
      bottom: auto;
      top: 0;
      left: 0;
      right: 0;
      width: auto;
      z-index: 5;

      @media(max-width: $screen-xs-max) {
        margin-top: calc(#{percentage(3 / 4)} - 30px);
      }
      @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        margin-top: calc(#{percentage(9 / 16)} - 30px);
      }
    }
    .carousel-control {
      z-index: 10;
      padding-top: percentage(600 / 1680);
      bottom: auto;
    }
  }
}

.carousel-inner > .item > div > a:hover {
  text-decoration: none;
}

.nested-anchor:hover {
  text-decoration: underline;
}
