#footer-newsleter-subscription {
  background: #3f4041;
  color: white;
  padding: 40px 0;

  .row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .newsletter-content {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }

  .newsletter-form {
    @media (min-width: $screen-sm) {
      padding-left: 0 !important;
    }
  }

  .newsletter-text {
    font-size: 14px;
  }

  .newsletter-headline {
    text-transform: uppercase;
    font-size: 24px;
    color: #fff;
  }
}

#footer-newsleter-subscription,
#popup-newsletter-subscription {
  .form-content {
    position: relative;
    width: 100%;
  }

  input[type=email] {
    border: 2px solid #404247;
    background: #fff;
    color: #404247 !important;
    padding: 10px 20px;
    width: 100%;

    &::placeholder {
      color: #404247 !important;
      opacity: 1;
    }
  }

  button {
    appearance: none;
    background: transparent;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 15px;
    color: #404247;
    border: 0;
    font-size: 20px;
  }
}


@media (min-width: 992px) {
  #c3373,
  #c3407,
  #c3367,
  #c3369,
  #c3365,
  #c3375,
  #c3373,
  #c3117,
  #c3377,
  #c4348 {
    .row > .col-12:first-of-type {
      left: 50%;
    }
    .row > .col-12:last-of-type {
      right: 50%;
    }
  }
}