#footer {
  background: $color-gray-dark;

  .navbar-collapse {
    ul {
      li {
        margin: 0 30px 0 0;

        a {
          padding: 25px 0;
          color: $color-gray-light;
          border: none;
          font-size: 14px;

          &:hover {
            color: $color-red;
          }
        }
      }
    }
  }

  .footer-copyright {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    span {
      font-size: 14px;
      padding: 25px 0;
      color: $color-gray-light;
      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    img {
      margin:0 14px 0 0;
      width: auto;
      height: 100%;
      max-height: 39px;
    }

    @media only screen and(max-width: 991px) {
      flex-direction: row;
      justify-content: space-between;

      img {
        margin: 0 0 0 10px;
      }
    }
  }
}

/** Mobile **/
@media (max-width: $screen-sm-max) {
  #footer ul {
    overflow: hidden;
    padding: 10px 0 0 0;
  }

  #footer ul li {
    a {
      padding: 10px 0;
    }
  }

  #footer .footer-copyright span {
    float: left;
  }
}