.container-search {
  position: relative;
  z-index: 1001;
  display: none;
  width: 100%;
  height: 74px;
  padding: 0 40px;
  animation-duration: 1s;
  background: #808080;

  .search-top-inner {
    width: 100%;
    animation-duration: 2s;

    form {
      float: left;
      width: 90%;
    }

    .input-group {
      width: 100%;
      padding-top: 24px;

      .icon-search3 {
        font-size: 24px;
        font-weight: 400;
        float: left;
        color: #fff;
      }
    }

    #search-close {
      font-size: 24px;
      font-weight: 400;
      float: right;
      margin-top: 26px;
      -moz-transition: color 0.1s ease-out, transform 0.2s ease-out;
      -o-transition: color 0.1s ease-out, transform 0.2s ease-out;
      -webkit-transition: color 0.1s ease-out, transform 0.2s ease-out;
      transition: color 0.1s ease-out, transform 0.2s ease-out;
      color: #fff;
      will-change: color;

      &:hover {
        cursor: pointer;
        color: $color-red;
      }
    }

    input.form-control {
      font-size: 22px;
      line-height: 24px;
      float: left;
      width: 85%;
      height: auto;
      margin: 0px;
      margin-left: 15px;

      padding: 0px;
      transition: none;
      color: #fff;
      border: none;

      border-radius: inherit;
      background: none;
      box-shadow: none;
    }

    input.form-control:focus {
      color: #fff;
    }

    input.form-control[placeholder],
    input[placeholder],
    [placeholder],
    *[placeholder],
    input::-webkit-input-placeholder {
      overflow: hidden;
      animation: type 5s steps(50, end);
      white-space: nowrap;
      color: #fff !important;
    }

    input:-moz-placeholder {
      overflow: hidden;
      animation: type 5s steps(50, end);
      white-space: nowrap;
      color: #fff !important;
    }

    input::-moz-placeholder {
      overflow: hidden;
      animation: type 5s steps(50, end);
      white-space: nowrap;
      color: #fff !important;
    }

    input:-ms-input-placeholder {
      overflow: hidden;
      animation: type 5s steps(50, end);
      white-space: nowrap;
      color: #fff !important;
    }

    span.input-group-btn {
      display: none;
    }
  }
}

.tx-indexedsearch {
  .tx-indexedsearch-searchbox {
    .row {
      margin: 0 -15px;
    }

    [class*="col-"] {
      padding: 0 15px;
    }
  }

  &-browsebox {
    margin: 10px 0 20px;
    padding-left: 0;
  }

  &-browselist {
    &-currentPage {
      font-weight: 500;
      text-decoration: underline;
    }
  }
}


/** Mobile **/
@media (max-width: 991px) {
  label, .form-group.tx-indexedsearch-form label {
    font-size: 18px;
  }

  input.submit,
  input.powermail_submit {
    padding: 8px 20px;
  }
}


.tx-indexedsearch-autocomplete {
  top: 74px;
  width: 100% !important;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-position: outside;
    border: 1px solid #AAAAAA;
    background: grey;

    > li {
      font-size: 22px;
      padding: .5em 1em;
      transition: color .2s ease-out;
      color: #fff;

      + li {
        border-top: 1px solid #AAAAAA;
      }

      &:hover,
      &:focus,
      &.highlighted {
        color: #db0812;
        background: #e5e5e5 !important;
      }
    }
  }
}