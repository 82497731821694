
$color-anthrazit: #0a2027;

$color-red: #db0812;
$color-gray-light: #dadada;
$color-gray: #7a8382;
$color-gray-dark: #444444;

$primary-color: $color-red;
$secondary-color: $color-gray-dark;


// Extra Large screen / wide desktop
//** Deprecated `$screen-xl` as of v3.0.1
$screen-xl:                  1400px !default;
$screen-xl-min:              $screen-xl !default;
//** Deprecated `$screen-xl-desktop` as of v3.0.1
$screen-xl-desktop:          $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-lg-max:              ($screen-xl-min - 1) !default;

$grid-float-breakpoint: $screen-md-min;

$icon-font-path: "/fileadmin/assets/bootstrap-sass/assets/fonts/bootstrap/";

$btn-default-color:              #fff;
$btn-default-bg:                 #444;
$btn-default-border:             $btn-default-bg;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base;
$btn-border-radius-large:        $border-radius-large;
$btn-border-radius-small:        $border-radius-small;