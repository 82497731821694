/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

@media(max-width: $screen-md-max) {
  .element-type-textpic {
    .teaser-item {
      height: 100%;
    }
  }
}

.element-type-textpic,
.element-type-textmedia {
  .teaser-item {
    @media(min-width: $screen-lg-min) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .teaser-item-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center right;

    @media(max-width: $screen-xs-max) {
      position: relative;
      @include aspectRatio(4, 3);
    }
    @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      position: relative;
      @include aspectRatio(16, 9);
    }
  }

  @media(max-width: $screen-md-max) {
    .fade-in {
      animation: none;
      opacity: 1;
    }
  }
  .teaser-item-content {
    .teaser-item-subheader {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 22px;
      border-bottom: 2px solid white;
      margin-bottom: 15px;
    }
    .teaser-item-header {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 35px;
      line-height: 1.2;
      margin-bottom: 40px;
    }

    .teaser-item-content-text {
      background-color: white;
      padding: 20px;

      @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding: 40px;
      }
      @media(min-width: $screen-lg-min) {
        padding: 40px;
      }
    }

    @media(max-width: $screen-md-max) {
      .c1x1 {
        padding-top: 0;
        > div {
          position: relative;
        }
      }
      .teaser-item-header {
        font-size: 25px;
      }
    }
    @media(min-width: $screen-lg-min) {
      min-width: 560px;
      margin-left: calc(#{percentage(1 / $grid-columns)} - 20px);
    }
    @media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      width: percentage((5 / $grid-columns));
    }
    @media(min-width: $screen-xl-min) {
      width: percentage((4 / $grid-columns));
    }
  }

  &.element-layout-teaser-default {
    .teaser-item-content {
      .teaser-item-content-text {
        background-color: white;
      }
    }
  }

  &.element-layout-teaser-gray {
    background-color: $color-gray-dark;
    .teaser-item-content {
      .teaser-item-content-text {
        background-color: $color-gray-dark;
        color: white;

        a {
          color: white;
          text-transform: none;
        }
      }
    }
  }

  &.element-layout-teaser-red {
    background-color: $color-red;
    .teaser-item-content {
      .teaser-item-content-text {
        background-color: $color-red;
        color: white;

        a {
          color: white;
          text-transform: none;
        }
      }
    }
  }

  &.with-delay .teaser-item-content {
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: .3s;
    animation-delay: 1s;
    opacity: 0;
  }
}

.element-type-textpic {
  ul {
    list-style: none;
    padding: 0;
    margin: 32px 0;

    li {
      padding: 0;
      border: none;
      margin: 0 0 10px 0 !important;

      a {
        font-size: 24px;
        font-weight: 400;
        border: none;

        &::before {
          display: inline;
          content: "»";
          font-size: 24px;
          font-weight: 400;
          padding: 0 18px 0 0;
        }

        &:hover,
        &:focus {
          text-decoration: none;
          background: none;
          color: #444;
        }
      }

      &:hover,
      &:focus {
        background: none;
        color: #444;

        a {
          color: #444;
        }
      }
    }
  }
}

div.ce-textpic-center .ce-textpic-center-outer,
div.ce-textpic-center .ce-textpic-center-inner {
  position: static;
  float: none;
  right: inherit;
}

div.ce-textpic .ce-textpic-imagewrap figure,
div.ce-textpic figure.ce-textpic-imagewrap, .image-wrap {
  display: block;
}

figcaption.ce-textpic-caption {
  display: block;
}

.ce-textpic-imagewrap, .image-wrap {
  margin: 0 0 30px 0;

  figcaption, span.caption {
    padding: 20px 10px;
    float: left;
    font-size: 14px;
    background: #f1f1f1;
    width: 100%;
    height: 100px;
  }
}

.header-image {
  .ce-textpic-imagewrap, .image-wrap {
    margin: 0;
  }
}
/*
body.fe-id-pagets__HomeSchneider {
  div.ce-textpic .ce-textpic-imagewrap figure,
  div.ce-textpic figure.ce-textpic-imagewrap {
    display: table;
  }
}
*/

body.fe-id-pagets__HomeSchneider,
body.fe-id-pagets__OverviewSchneider,
.precision.product-schneider-om,
.opthalmics.product-schneider-om {
  .ce-textpic-imagewrap, .image-wrap {
    margin: 0;
  }
}