.sitemap-top-inner {
  width: 100%;
  animation-duration: 2s;

  h3.title {
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 50px 0 30px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    @include make-row;

    > li {
      @include make-xs-column(15, 15);
      @include make-md-column(5, 15);
      @include make-lg-column(3, 15);

      padding-bottom: 40px;

      > a {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 30px 0;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        padding: 0;

        li {
          width: 100%;
          margin: 0 0 30px 0;

          a {
            font-size: 16px;
            margin: 0;
            padding: 0 0 2px 0;
            width: 100%;
            border-bottom: 2px solid #fff;
            display: block;

            &::before {
              content: "»";
              padding: 0 10px 0 0;
              font-weight: 500;
              display: inline;
            }

            &:hover {
              text-decoration: none;
              padding-left: 10px;
            }
          }

          &:hover {
            padding: 0;
          }

          ul {
            li {
              margin: 0;

              a {
                border-bottom: 1px solid #fff;
                padding: 4px 0;
                font-weight: 400;

                &:hover {
                  text-decoration: none;
                  background: #fff;
                  color: #808080;
                  padding: 4px 15px;
                }

                &::before {
                  content: "";
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}