.fh,
.full-height {
  height: 100%;
}

.c1x1 {
  /* 1:1 Aspect Ratio */
  @include aspectRatio(1, 1);
}
.c16x9 {
  /* 16:9 Aspect Ratio */
  @include aspectRatio(16, 9);
}
.c4x3 {
  /* 4:3 Aspect Ratio */
  @include aspectRatio(4, 3);
}

.h-equal,
.equalheight {
  > .row {
    @include row-eq-height;
    &:before,
    &:after {
      display: none;
    }

    > [class*=col-] {
      > div,
      .h-equal,
      .equalheight {
        width: 100%;
      }
    }
  }
}

//.h-equal,
//.equalheight {
  .img-responsive {
    min-width: 100%;
  }
//}