@mixin aspectRatio($width: 1, $height: 1) {
  padding-top: percentage($height / $width); /* 1:1 Aspect Ratio */

  position: relative;
  width: 100%;

  > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}