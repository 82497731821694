.element-type-powermail_pi1 {
  .powermail_fieldwrap_text {
    &.layout2 {
      float: left;
      padding: 6px 0;
    }
  }
}

.powermail_message.powermail_message_error {
  padding: 5px 10px;
  background-color: $color-gray-light;
  border: 2px solid $color-red;
  list-style: none;
}

.powermail_fieldwrap_type_file {
  label {
    display: inline-block;
    font-size: 16px;
    position: relative;
    width: 100%;
  }

  .controls {
    cursor: pointer;
    position: relative;
  }

  input {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 3em;
    outline: none;
    visibility: hidden;
  }

  .title_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //content: attr(data-title);
    border: 2px dashed $color-gray;
    min-height: 3em;
    line-height: 1.3em;
    padding-top: 12px;
    opacity: 1;
    text-align: center;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }

  .controls:hover .title_overlay {
    border-style: solid;
    box-shadow: inset 0 0 0 2px $color-gray;
  }
}