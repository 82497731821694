.header-image {
  .element-type-textmedia {
    iframe {
      max-width: 100%;
    }

    .teaser-item-media {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      @media(max-width: $screen-md-max) {
        position: relative;
        padding-bottom: percentage(600 / 1680);
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

#main .element-type-textmedia {
  video {
    max-width: 100%;
  }
}