/** Main **/
.navbar-collapse.collapse {
  margin: 0 -10px;

  > .nav {
    > li {
      margin: 0;

      transition: all 0.2s ease-out;

      > a {
        border-bottom: 3px solid transparent;
        font-size: 18px;
        padding: 0 20px 22px;
        transition: all 0.2s ease-out;

        &:hover, &:focus {
          border-bottom: 3px solid #444;
          background: none;
          color: #444;
        }
      }

      &.active {
        > a {
          border-bottom: 3px solid $color-red;
          background: none;
          color: $color-red;
        }
      }
    }
  }
}

/** Mobile **/
@media (max-width:1210px) {
  .navbar-collapse.collapse > .nav > li {
    margin: 0;

    a {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}

@media (max-width:1150px) {
  .navbar-collapse.collapse > .nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width:1050px) {
  .navbar-collapse.collapse > .nav > li > a {
    font-size: 16px;
  }
}

.navbar-collapse.collapse {
  display: none!important;

  @media(min-width: $screen-md-min) {
    display: block!important;
  }
}


/** mmenu **/
.mm-menu {
  background: $color-red;
  color: #fff;
}

.mm-menu .mm-navbar {
  border-bottom-color: $color-red;
  border-bottom-width: 1px;
}

.mm-menu .mm-navbar > *,
.mm-menu .mm-navbar a {
  color: #fff;
}

.mm-menu .mm-listview > li .mm-prev::before,
.mm-menu .mm-listview > li .mm-next::after,
.mm-menu .mm-listview > li .mm-arrow::after,
.mm-menu .mm-navbar .mm-btn::before,
.mm-menu .mm-navbar .mm-btn::after {
  border-color: #fff;
}

.mm-listview > li:not(.mm-divider)::after {
  left: 0;
}

.mm-listview > li > a, .mm-listview > li > span {
  padding: 15px 10px 20px;
  font-size: 18px;
}

#nav-icon3 {
  display: inline-block;
  width: 25px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  color: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 8px;
}

#nav-icon3 span:nth-child(4) {
  top: 16px;
}


// mmenu open
.mm-opened {
  #nav-icon3 span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%;
  }

  #nav-icon3 span:nth-child(2) {
    transform: rotate(45deg);
  }

  #nav-icon3 span:nth-child(3) {
    transform: rotate(-45deg);
  }

  #nav-icon3 span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}





/** Language Menu **/
#lang {
  margin: 0 0 0 40px;

  ul {
    li {
      padding: 14px 0;
      color: #999;
      cursor: default;
      font-size: 14px;

      a {
        color: #999;
        padding: 0 2px;
        font-weight: 500;
        display: block;
        float: left;

        &:hover {
          background: none;
          color: $color-red;
        }
      }

      &.active {
        a {
          background: none;
          color: $color-red;
        }
      }
    }
  }
}


/** Breadcrumb **/
#breadcrumb-anchorSubnav-sticky-wrapper {
  #breadcrumb-anchorSubnav {
    width: 100%;
    max-width: 1680px;
    z-index: 500;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
    -o-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
    -ms-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 6px 6px transparent;
  }
}

.breadcrumb-anchorSubnav {
  background: #f1f1f1;
  padding: 0 20px;
  overflow: hidden;

  @media(min-width: $screen-sm-min) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.breadcrumb-wrap {
  overflow: hidden;

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    float: left;
    width: 100%;
    background: #f1f1f1;

    span.svg-arrow {
      display: block;
      float: left;
      padding: 0 15px;
      width: 54px;

      img {
        height: 50px;
      }
    }

    span.icon {
      color: #d1d1d1;
      display: block;
      float: left;
      font-size: 20px;
      padding: 14px 0;

      &.arrow {
        color: #e1e1e1;
        font-size: 52px;
        padding: 0;
      }
    }

    li {
      color: #d1d1d1;
      font-size: 16px;
      float: left;

      a {
        color: #d1d1d1;

        &:hover {
          color: #6d6d6d;
          text-decoration: none;
        }
      }

      &.active {
        color: #6d6d6d;
      }

      .breadcrumb-text {
        display: inline-block;
        padding: 14px 0;
      }
    }
  }
}


/** AnchorSubnav **/
.anchorSubnav {
  float: right;

  ul {
    li {
      float: left;
      margin: 0 0 0 35px;

      a {
        padding: 14px 0;
        color: #888;

        &:after {
          position: absolute;
          content: "";
          left: 50%;
          -webkit-transform: translateZ(-50%);
          -ms-transform:		 translateZ(-50%);
          transform: 				 translateZ(-50%);

          opacity: 0;
          -webkit-transition: all 400ms ease-in-out;
          -moz-transition:		all 400ms ease-in-out;
          -ms-transition:			all 400ms ease-in-out;
          -o-transition:			all 400ms ease-in-out;
          transition:					all 400ms ease-in-out;

          bottom: 0;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 7.5px solid #888;
        }

        &:hover {
          color: #888;
          background: none;

          &:after {
            opacity: 1;
          }
        }
      }

      &.active.last a {
        color: #6d6d6d;
        position: relative;

        &:after {
          border-bottom-color: #6d6d6d;
          opacity: 1;
        }
      }
    }
  }
}


/** Footer Menu **/
#footer {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      margin: 0;
      margin-right: 15px;

      @media(min-width: $screen-lg-min) {
        margin-right: 30px;
      }

      &.last {
        margin-right: 0;
      }

      a {
        color: $color-gray-light;
        font-size: 14px;
        padding: 25px 0;
        display: block;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}


/** Mobile **/
#mmenu {
  z-index: 100;
}

@media (max-width:1250px) {
  body.fe-id-pagets__OpthalmicsProductSchneider,
  body.fe-id-pagets__PrecisionOpticsProductSchneider,
  .fe-id-pagets__OverviewSchneider {
    #breadcrumb-anchorSubnav-sticky-wrapper {
      height: auto !important;
    }

    #breadcrumb-anchorSubnav {
      padding: 0;

      .row {
        margin: 0;
      }

      [class*="col-"] {
        width: 100%;
        padding: 0;
      }

      .breadcrumb-wrap {
        padding: 0 20px;

        @media(min-width: $screen-sm-min) {
          padding: 0 40px;
        }
      }

      .anchorSubnav {
        float: left;
        width: 100%;
        border-top: 1px solid #e1e1e1;

        padding: 0 20px;
        @media(min-width: $screen-sm-min) {
          padding: 0 40px;
        }

        ul li {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}


/** Mobile **/
@media (max-width:991px) {
  .anchorSubnav ul li {
    margin: 0 20px 0 0;
  }
}

@media (max-width: $screen-sm-max) {
  #header {
    .navbar-mobil-switch {
      display: block !important;
    }
  }
  .container-header {
    .row:nth-child(2) {
      display: none !important;
    }
  }
  #header-sticky-wrapper,
  #breadcrumb-anchorSubnav-sticky-wrapper {
    height: auto !important;
  }

  #header-sticky-wrapper.is-sticky #header,
  #breadcrumb-anchorSubnav-sticky-wrapper #breadcrumb-anchorSubnav {
    position: static !important;
  }
}

@media (max-width:460px) {
  .anchorSubnav ul li a {
    padding: 10px 0;
  }
}