.countdown-content {
	min-height: 100%;
	position: relative;

	.countdown {
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		overflow: hidden;
    text-align: center;

		@media(max-width: $screen-md-max) {
			position: relative;
			margin: 40px 0;
		}

		> li {
      display: inline-block;
			position: relative;
			text-align: center;
			padding: 0 12px;
			margin: 0 22px 0 0 !important;

      @media(max-width: 479px) {
       padding: 0 8px;
        margin: 0 !important;
      }

			.countdown-value {
				font-size: 40px;
				font-weight: bold;
				text-transform: uppercase;
			}

			.countdown-unit {
				font-size: 12px;
				text-transform: uppercase;
			}

			&:after {
				content: '';
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 );
				height: 40px;
				position: absolute;
				right: -10px;
				top: 20px;
				width: 2px;
        @media(max-width: 479px) {
          display: none;
        }
			}

			&:last-child {
				margin: 0;

				&:after {
					display: none;
				}
			}
		}
	}
}

.countdown-gray {
	.teaser-item-content-text {
		background-color: #444 !important;
	}
}

.countdown-red {
	.teaser-item-content-text {
		background-color: #db0812 !important;
	}
}

.countdown-gray,
.countdown-red {
  a {
    color: white;

    &:hover,
    &:focus {
      color: white;
    }
  }
	.teaser-item-content-text {
		color: #fff;
	}

	.countdown {
		> li {
			&:hover {
				color: #fff !important;
			}

			&:after {
				content: '';
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%);
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 );
			}
		}
	}
}

#c2623,
#c2638 {
  @media(max-width: 1199px) {
    display: none !important;
  }
}