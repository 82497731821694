#popup-newsletter-subscription {
  // @include position(fixed, 50% 0 null null);
  position: fixed;
  top: 50%;
  right: 0;

  background-color: $color-red;
  padding: 30px;
  max-width: $modal-sm;
  opacity: 0;
  pointer-events: none;
  transform: translate(100%, -50%);
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  z-index: 1101;

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate(0, -50%);
  }

  &, a, h3 {
    color: white;
  }

  .newsletter-close {
    position: absolute;
    top: 20px;
    right: 20px;

    cursor: pointer;
    font-size: 26px;
  }

  .icon-newspaper {
    font-size: 32px;
  }

  .newsletter-text {
    margin-bottom: 20px;
  }

  .newsletter-link {
    float: right;
    font-size: 26px;
    text-decoration: none;
  }

  input[type=email] {
    border: none;
  }
}