.teaserbox {
	visibility: hidden;

	.image-wrap {
		overflow: hidden;

		img {
			transition: transform .3s ease-in-out;
		}

		&:focus img,
		&:hover img {
			transform: scale(1.05);
		}
	}

	.ce-header {
		position: absolute;
		bottom: 50px;
		left: 0;
		padding: 0 43px;
		z-index: 30;

		h3 {
			margin: 0;
			color: #fff;
			font-size: 35px;

			span {
				color: #fff;
				font-size: 35px;
			}
		}
	}

  .ce-textpic-left .ce-textpic-imagewrap {
		float: none;
	}

  .ce-textpic-image {
		figcaption {
			display: none;
		}
	}

	a {
		color: #fff;
	}

	display: block;

	h3 {
		display: inline-block;
		overflow: hidden;
		padding-bottom: 4px;
		position: relative;

		&:after {
			background-color: white;
			content: "";
			display: block;
			height: 2px;
			width: 100%;

			position: absolute;
			left: 0;
			bottom: 0;

			transform: translate3d(-100%, 0, 0);
			transition: transform .2s ease-in;
		}
	}

	&:focus,
	&:hover {
		h3:after {
			transform: translate3d(0, 0, 0);
		}
	}
}

.element-layout-teaserbox a {
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

.teaserbox .image-wrap::after {
	background: rgba(76, 76, 76, 0);
	background: -moz-linear-gradient(top, rgba(76, 76, 76, 0) 0%, rgba(38, 38, 38, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76, 76, 76, 0)), color-stop(50%, rgba(38, 38, 38, 0)), color-stop(100%, rgba(0, 0, 0, 0.5)));
	background: -webkit-linear-gradient(top, rgba(76, 76, 76, 0) 0%, rgba(38, 38, 38, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	background: -o-linear-gradient(top, rgba(76, 76, 76, 0) 0%, rgba(38, 38, 38, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	background: -ms-linear-gradient(top, rgba(76, 76, 76, 0) 0%, rgba(38, 38, 38, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	background: linear-gradient(to bottom, rgba(76, 76, 76, 0) 0%, rgba(38, 38, 38, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4c4c4c', endColorstr='#000000', GradientType=0);

	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	height: 100%;
	display: block;
	z-index: 20;
	pointer-events: none;
}

body:not(.fe-id-pagets__HomeSchneider) {
  .ce-default {
		a.link-inner {
			&:hover {
				.teaserbox {
					h3, span {
						color: $color-red;
					}
				}
			}
		}
	}
}

/** Mobile **/
@media (max-width:1199px) {
  .teaserbox .ce-header h3 {
		font-size: 25px;
	}
}

@media (max-width:925px) {
  .teaserbox .ce-header {
		padding: 0 20px;
	}
}

@media (max-width:768px) {
  .teaserbox .ce-header {
		padding: 0 10px;
	}
}