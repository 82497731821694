.news-categories {
  margin: 30px 0 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {

      > a {
        text-transform: uppercase;
        background-color: $color-gray-dark;
        display: block;
        color: white;
        padding: 5px;

        &:hover {
          text-decoration: none;
          background-color: $color-red;
        }
      }
      &.active {
        a {
          text-decoration: none;
          background-color: $color-red;
        }
      }
    }
  }

  @media(min-width: $screen-md-min) {
    ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        & + li {
          padding-left: 1px;
        }

        a {
          height: 100%;
        }
      }
    }
  }
}