/* Messeveranstaltungen Latest **/
.news {
  height: 100%;
}

.news-layout-12 {
  height: 100%;
  background: $color-red;
  padding: 20px;
  color: white;

  a,
  h2 {
    color: white;
  }

  .article {
    margin-bottom: 40px;

    .header {
      a {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .teaser-text {
      margin: 0;
      border-top: 1px solid white;
      border-bottom: 1px solid white;

      p {
        line-height: 28px;
        margin: 0;
        border-bottom: 1px solid white;

        &:last-child {
          border-bottom: 0 none;
        }
      }
    }
  }
  .link-to-all {
    line-height: 28px;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }

    &:before {
      content: '››';
      display: inline;
      padding-left: 1px;
      margin-right: 5px;
      font-weight: 500;
    }
  }
}