#page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mm-page {
  display: flex;
  flex-direction: row;
}

.inner-page {
  background: #ffffff;
  flex: 1 0 auto;
}

#footer {
  flex: 0 0 auto;
}

#body {
  background: #fff;
  overflow: hidden;
  clear: both;

  #content {
    padding: 0;
  }

  .container-fullwidth {
    margin: 0;
  }
}

/** Text-Element im Header-Image Bereich **/
.header-image {
  position: relative;

  .element-type-text {
    background: #fff;
    padding: 40px;

    ul {
      margin: 32px 0;

      li {
        padding: 0;
        border: none;
        margin: 0 0 10px 0 !important;

        a {
          font-size: 24px;
          font-weight: 400;
          border: none;

          &::before {
            display: inline;
            content: "»";
            font-size: 24px;
            font-weight: 400;
            padding: 0 18px 0 0;
          }

          &:hover {
            background: none;
            color: #444;
          }
        }

        &:hover {
          background: none;
          color: #444;

          a {
            color: #444;
          }
        }
      }
    }
  }
}

body.fe-id-pagets__HomeSchneider {
  #content {
    div.ce-textpic-center .ce-textpic-center-outer,
    div.ce-textpic-center .ce-textpic-center-inner {
      float: none;
      right: inherit;
    }

    .ce-default {
      /*height: 420px;*/

      .element-type-text a {
        display: block;

        &:hover, &:focus {
          text-decoration: none;

          h3 {
            text-decoration: underline;
          }
          p {
            text-decoration: none;
            color: #444;
          }
        }
      }

      &.element-type-text {
        position: relative;
        padding: 50px 40px;

        &.bg-color {
          float: none;
          padding: 0;

          a {
            color: #fff;

            &:hover {
              text-decoration: none;

              p {
                text-decoration: none;
                color: inherit;
              }
            }
          }

        }

        .ce-header {
          h3 {
            margin: 0 0 50px 0;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 35px;
          }
        }

        p {
          margin: 0 0 20px 0;

          a {
            /*position: absolute;*/
            bottom: 50px;
            left: 40px;
            font-size: 24px;
            text-transform: uppercase;
          }
        }
      }

      p.read-more-link-startpage {
        position: absolute;
        bottom: 50px;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 0;

        @media (max-width: 767px) {
          bottom: 25px;
        }

        &:before {
          content: "»";
          margin-right: 20px;
        }
      }

      .bg-color-inner {
        float: left;
        padding: 50px 40px;

        .ce-header {
          h3 {
            margin: 0 0 50px 0;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 35px;
          }
        }
      }
    }
  }
}

.element-type-bullets {
  margin: 0 0 30px 0;
}

.lines-schneider-om {
  #body {
    #content {
      padding: 90px 0 0;
    }
  }
}

body.fe-id-pagets__OverviewSchneider {
  #body {
    #content {
      .grid-12 {
        .container-fullwidth {
          .container {
            padding: 0;

            .ce-header {
              padding: 40px 40px 0;
            }

            .element-type-text {
              p {
                padding-left: 40px;
                padding-right: 40px;
              }
            }
            .news {
              padding: 0 40px;
            }
          }
        }
      }
    }
  }
}

#body.opthalmics.product-schneider-om,
#body.precision.product-schneider-om {
  #content {
    .grid-12 {
      .container-fullwidth {
        .container {
          padding: 0;

          .element-type-text {
            padding: 0;
          }

          .ce-header {
            padding: 40px 40px 0;
          }

          .element-type-text {
            p {
              padding-left: 40px;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
}

.content-gray {
  background: #e6e6e6;
  padding: 20px;

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 80px calc(#{percentage(1 / $grid-columns)} + 20px);
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1400px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

/** Mobile **/
@media (max-width: 1661px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default .bg-color-inner .ce-header h3,
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text .ce-header h3,
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 .ce-header h3 {
    font-size: 25px;
    margin: 0 0 30px 0;
  }

  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text p a {
    font-size: 20px;
  }
}

@media (max-width: 1472px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text p {
    font-size: 14px;
  }

  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text p a {
    font-size: 18px;
  }
}

@media (max-width: 1298px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text,
  body.fe-id-pagets__HomeSchneider #content .ce-default .bg-color-inner {
    padding: 10%;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  body.fe-id-pagets__OverviewSchneider #body #content .grid-12 .container-fullwidth .container .ce-header {
    padding: 30px 15px 0;
  }

  body.fe-id-pagets__OverviewSchneider #body #content .grid-12 .container-fullwidth .container .element-type-text p {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1185px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default .bg-color-inner .ce-header h3,
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text .ce-header h3,
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 .ce-header h3 {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 1130px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text p {
    text-overflow: ellipsis;
    /*white-space: nowrap;*/
    overflow: hidden;
    height: 100px;
    display: inline-block;
  }
}

@media (max-width: 1010px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default .bg-color-inner .ce-header h3,
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text .ce-header h3,
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 .ce-header h3 {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body.fe-id-pagets__HomeSchneider #content .ce-default p.read-more-link-startpage,
  .teaserbox .ce-header {
    bottom: 20px;
  }
}

@media (max-width: 991px) {
  .precision.product-schneider-om .product-show .content,
  .opthalmics.product-schneider-om .product-show .content {
    padding: 5% 0 0;
  }

  body.fe-id-pagets__HomeSchneider #content .ce-default .bg-color-inner .ce-header h3,
  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text .ce-header h3,
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 .ce-header h3 {
    font-size: 25px;
  }

  body.fe-id-pagets__HomeSchneider #content .ce-default.element-type-text p {
    display: block;
    height: auto;
    width: auto;
    overflow: inherit;
    text-overflow: inherit;
    font-size: inherit;
  }
}

@media (max-width: 560px) {
  body.fe-id-pagets__MainSchneider #body #content,
  .lines-schneider-om #body #content {
    padding: 5% 0;
  }

  .header-image .element-type-text ul li {
    a {
      font-size: 20px;
    }

    &::before {
      font-size: 20px;
      padding: 0 10px 0 0;
    }
  }
}