.fade-in {
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  -webkit-animation:fadeIn ease-in-out 1;
  -moz-animation:fadeIn ease-in-out 1;
  animation:fadeIn ease-in-out 1;

  -webkit-animation-fill-mode:both;
  -moz-animation-fill-mode:both;
  animation-fill-mode:both;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.delay-2 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}