#social-media-sidebar {
  position: fixed;
  top: 50%;
  z-index: 30;

  left: 0;
  width: 34px;
  background-color: #fff;
  padding-top: 4px;

  -webkit-transform: translateY(-50%);
  -ms-transform:     translateY(-50%);
  transform:         translateY(-50%);

  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
  box-shadow:         0 0 20px 0 rgba(0,0,0,0.2);

  @media (max-width: 787px) {
    left: -29px;

    -webkit-transition: all 400ms ease-in-out;
    -moz-transition:    all 400ms ease-in-out;
    -ms-transition:     all 400ms ease-in-out;
    -o-transition:      all 400ms ease-in-out;
    transition:         all 400ms ease-in-out;

    &:hover {
      left: 0;
    }
  }

  > a {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin: 0 4px 4px 0;
    color: #fff;
    font-size: 18px;
    text-align: center;

    -webkit-transition: all 400ms ease-in-out;
    -moz-transition:    all 400ms ease-in-out;
    -ms-transition:     all 400ms ease-in-out;
    -o-transition:      all 400ms ease-in-out;
    transition:         all 400ms ease-in-out;

    &:hover {
      text-decoration: none;
      opacity: 0.9;
    }
  }
  .facebook {
    background-color: #3c5193;
  }
  .twitter {
    background-color: #32a9e1;
  }
  .linkedin {
    background-color: #0d77b6;
  }
  .google-plus {
    background-color: #da3f2d;
  }
  .pinterest {
    background-color: #ca1519;
  }
  .rss-feed {
    background-color: #f8ab21;
  }
}

html.touch #social-media-sidebar {
  display: none;
}