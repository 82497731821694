body.fe-id-pagets__HomeSchneider {
  .element-type-plugin-news_pi1 {
    padding: 50px 40px 0;
    background: #fff;

    header {
      h3 {
        margin: 0 0 50px 0;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 35px;
      }
    }

    .news {
      .article {
        border: none;
        margin: 0 0 10px 0;

        .content {
          display: block;
          width: 100%;
          padding: 0;
          background: none;

          h3 {
            color: #444;
            font-size: 16px;
            font-weight: 500;
            margin: 0;

            a {
              &:hover {
                text-decoration: none;
              }
            }
          }

          .teaser-text {
            p {
              margin: 0;
            }
          }
        }
        a {
          display: inline-block;
          width: 100%;
          &:hover {
            color: #444;
            h3 {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

/** Mobile **/
@media (max-width:1433px) {
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 {
    .news .article {
      display: none;

      .footer {
        width: 100%;

        p {
          margin: 0 0 15px 0;
        }
      }

      .content {
        width: 100%;
        float: left;
      }

      &:first-child {
        display: block;
      }
    }
  }
}

@media (max-width:1298px) {
  body.fe-id-pagets__HomeSchneider .element-type-plugin-news_pi1 {
    padding: 10%;
  }
}

/* News Detail **/
.news-single {
  .article {
    border: none;

    h3 {
      margin: 0 0 10px 0;
    }

    .footer {
      color: #888;
    }

    .news-img-wrap {
      float: none;
      margin: 0;
      width: 100%;

      .outer {
        margin: 0 0 30px 0;

        p {
          margin: 0;
        }
      }

      a {
        float: none;
      }
    }

    .news-backlink-wrap {
      &::before {
        content: "«";
        color: $color-red;
      }

      a {
        color: $color-red;
      }
    }
  }
}