.element-type-powermail_pi1 {
  margin: 0 0 40px 0;
}

.radio input[type=radio], .radio-inline input[type=radio] {
  top: 10px
}

/** Submit Button **/
input.submit,
input.powermail_submit {
  background: #444;
  border: 2px solid #444;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px 40px;
  margin: 0 0 40px 0;

  transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;

  &:hover {
    background: #fff;
    color: #444;
    border-color: #444;
  }
}

/** Input **/
.form-control {
  height: 44px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 44px;
  box-shadow: inherit;
  border-radius: 0;
  border: 2px solid #ececec;
  color: #888;
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    border-color: $color-gray-light;
    box-shadow: none;
  }

  &.powermail_file {
    height: auto;
    margin: 0 0 40px 0;
  }

  &.parsley-error {
    color: $color-red;
    border: 2px solid $color-red;
    background: #fcf2f5;
  }
}

legend {
  margin: 0;
}

.powermail_label,
.control-label {
  color: #888;
  font-size: 16px;
  min-height: inherit;
  line-height: inherit;
}

/** Errors **/
.parsley-errors-list {
  color: $color-red;
}

.radio label,
.checkbox label {
  font-size: 16px;
}

/** Placeholder **/
input[placeholder],
[placeholder],
*[placeholder],
input::-webkit-input-placeholder {
  color: #888 !important;
  opacity:  1;
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  color: #888 !important;
  opacity:  1;
  text-overflow: ellipsis;
}
input::-moz-placeholder {
  color: #888 !important;
  opacity:  1;
  text-overflow: ellipsis;
}
input:-ms-input-placeholder {
  color: #888 !important;
  opacity:  1;
  text-overflow: ellipsis;
}

.powermail_fieldset .select2-container {
  width: 100% !important;
}

label,
.form-group.tx-indexedsearch-form label {
  padding: 0;
  line-height: 44px;
  font-size: 22px;
  font-weight: 400;
}

.tx-powermail {
  .form3cols {
    @media (min-width: 575px) and (max-width: 991px) {
      .powermail_fieldwrap_anrede {
        width: 21%;
        margin-right: 2%;
        float: left;
      }

      .powermail_fieldwrap_vorname{
        width: 37.5%;
        float: left;
      }

      .powermail_fieldwrap_nachname {
        width: 37.5%;
        float: left;
        margin-left: 2%;
      }

      .powermail_fieldwrap_country > div {
        display: flex;
        flex-wrap: wrap;

        .select2 {
          order: 1;
        }

        .parsley-errors-list {
          order: 2;
        }
      }
    }

    @media (min-width: 1130px) and (max-width: 1190px) {
      .powermail_fieldwrap_anrede {
        width: 21%;
        margin-right: 2%;
        float: left;
      }

      .powermail_fieldwrap_vorname{
        width: 37.5%;
        float: left;
      }

      .powermail_fieldwrap_nachname {
        width: 37.5%;
        float: left;
        margin-left: 2%;
      }

      .powermail_fieldwrap_country > div {
        display: flex;
        flex-wrap: wrap;

        .select2 {
          order: 1;
        }

        .parsley-errors-list {
          order: 2;
        }
      }
    }

    @media (min-width: 1690px) {
      .powermail_fieldwrap_anrede {
        width: 21%;
        margin-right: 2%;
        float: left;
      }

      .powermail_fieldwrap_vorname{
        width: 37.5%;
        float: left;
      }

      .powermail_fieldwrap_nachname {
        width: 37.5%;
        float: left;
        margin-left: 2%;
      }

      .powermail_fieldwrap_country > div {
        display: flex;
        flex-wrap: wrap;

        .select2 {
          order: 1;
        }

        .parsley-errors-list {
          order: 2;
        }
      }
    }
  }
}