.opthalmics {
  h1, h2, h3, h4, h5 {
    color: #317184;
  }
  a {
    color: #317184;

    &:hover {
      background: #317184;
      color: #fff;
    }
  }

  .container-gray {
    a {
      &:hover {
        background: #fff;
        color: $color-gray;
      }
    }
  }
}

.precision {
  h1, h2, h3, h4, h5 {
    color: #454576;
  }
  a {
    color: #454576;

    &:hover {
      background: #454576;
      color: #fff;
    }
  }

  .container-gray {
    a {
      &:hover {
        background: #fff;
        color: $color-gray;
      }
    }
  }
}
