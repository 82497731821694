@mixin listLined($color: #444444, $hoverColor: #ffffff) {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 30px;

  > li {
    list-style: none;
    > a {
      color: $color;
      display: block;
      padding: 4px 1px;
      border-bottom: 1px solid $color;
      padding-left: 10px;

      &:before {
        content: '›';
        display: inline;
        margin-right: 5px;
        font-weight: 500;
        position: absolute;
        margin-left: -8px;
      }

      &:hover,
      &:focus {
        padding-left: 16px;
        background-color: $color;
        color: $hoverColor;
        text-decoration: none;
      }
    }
  }
}