.news-single {
  .article {
    .news-img-wrap {
      a {
        padding: 0;
        border: 0 none;
      }
      img {
        max-width: 100%;
      }
    }
    .footer {
      border: 0 none;
      margin-top: 0;
      padding-top: 0;
    }
  }
  &.news-single-14 {
    .teaser-item-content-text {
      .news-list-date {
        margin-bottom: 20px;
      }
      .teaser-text {
        border-top: 1px solid #444444;
        border-bottom: 1px solid #444444;
        color: inherit;

        p {
          padding: 5px 0;
          margin: 0;
        }

        @media(min-width: $screen-lg-min) {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 40px;
        }
      }
    }
    .news-text-wrap {
      margin-bottom: 30px;
    }

    @media(min-width: $screen-lg-min) {
      .news-text-wrap {
        margin-top: 60px;
      }
      .news-backlink-wrap {
        margin-bottom: 60px;
      }
    }
  }
}