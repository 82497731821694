.news .nav ul,
.news .nav a {
  float: none;
}

.news {
  .article {
    &:after {
      display: none;
    }
  }
}

.news-list-view {
  .article {
    position: relative;
    margin-bottom: 20px;

    &.articletype-0 {
      border-bottom: 6px solid $color-gray-dark;
      margin-bottom: 40px;
      padding-bottom: 40px;

      @media(min-width: $screen-md-min) {
        &.topnews {
          margin-bottom: 0;
        }
      }

      .more {
        position: absolute;
        bottom: 5px;
        right: 0;
        line-height: 28px;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '››';
          display: inline;
          padding-left: 1px;
          margin-right: 5px;
          font-weight: 500;
        }
      }
    }

    .news-img-wrap {
      position: relative;
      float: none;
      width: 100%;
      margin: 0;

      a, img {
        float: none;
        border: 0 none;
        padding: 0;
      }

      img {
        width: 100%;
      }

      .news-img-title {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        padding: 5px 10px;
      }
    }

    .header-meta {
      margin-top: 20px;
      text-transform: uppercase;
    }
    .header {
      h1 {
        margin: 20px 0;
        font-weight: 400;
      }
      h2, h3 {
        margin: 15px 0;
        font-weight: 400;
      }
    }

    .link-all {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &, &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}